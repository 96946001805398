/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'browser-chrome': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M16 8a8 8 0 01-7.022 7.94l1.902-7.098a3 3 0 00.05-1.492A3 3 0 0010.237 6h5.511A8 8 0 0116 8M0 8a8 8 0 007.927 8l1.426-5.321a3 3 0 01-.723.255 3 3 0 01-1.743-.147 3 3 0 01-1.043-.7L.633 4.876A8 8 0 000 8m5.004-.167L1.108 3.936A8.003 8.003 0 0115.418 5H8.066a3 3 0 00-1.252.243 2.99 2.99 0 00-1.81 2.59M8 10a2 2 0 100-4 2 2 0 000 4"/>',
    },
});
